<template>
  <section>
    <vpn-header ref="header"></vpn-header>
    <div class="login-wrapper" v-loading="loading">
      <div class="login-body login-scale">
        <img class="login-img" src="@/images/background/login.png" alt="" />
        <div class="login-box">
          <h2>Welcome Login</h2>
          <el-form
            class="login-form"
            ref="loginForm"
            :model="loginForm"
            :rules="loginRules"
            label-width="0">
            <el-form-item prop="email">
              <el-input type="email" v-model="loginForm.email" maxlength="30" placeholder="Email">
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input type="password" v-model="loginForm.password" maxlength="20" placeholder="Password" @keydown.enter.native="handleLogin" show-password>
              </el-input>
            </el-form-item>
            <!-- <el-form-item class="login-remember">
              <el-checkbox v-model="loginForm.remember">记住密码</el-checkbox>
            </el-form-item> -->
            <el-form-item class="login-submit">
              <el-button type="primary" @click="handleLogin">Log in to account</el-button>
            </el-form-item>
          </el-form>
          <dl class="login-forgot">
            <dt><el-link type="warning" @click="gotoForgot">Forgot Password?</el-link></dt>
            <dd>Don't have an account?<el-link type="primary" @click="gotoSign">Sign Up Now</el-link></dd>
          </dl>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import md5 from 'js-md5'
import VpnHeader from '@/components/layout/header'
import { login } from '@/apis/common'

export default {
  components: { VpnHeader },
  props: {},
  data () {
    return {
      loading: false,
      loginForm: {
        email: '',
        password: '',
        remember: true
      },
      loginRules: {
        email: [
          { required: true, message: 'Please enter the Email', trigger: 'blur' },
          { type: 'email', message: 'Please enter the correct email', trigger: ['blur'] }
        ],
        password: [
          { required: true, message: 'Please enter the Password', trigger: 'blur' },
          { min: 6, max: 20, message: 'Password length between 6-20 digits', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.common.userInfo
    })
  },
  watch: {},
  methods: {
    ...mapMutations(['setUserInfo']),
    handleLogin() {
      this.$refs['loginForm'].validate(valid => {
        if (valid) {
          this.loading = true
          login({
            email: this.loginForm.email,
            password: md5(this.loginForm.password)
          }, (res) => {
            // console.log(res)
            this.setUserInfo(res)
            if (this.loginForm.remember) {
              window.document.cookie = 'email' + '=' + this.loginForm.email + ';'
              window.document.cookie = 'password' + '=' + this.loginForm.password + ';'
              window.document.cookie = 'remember' + '=' + this.loginForm.remember + ';'
            } else {
              window.document.cookie = 'email' + '=' + '' + ';'
              window.document.cookie = 'password' + '=' + '' + ';'
              window.document.cookie = 'remember' + '=' + this.loginForm.remember + ';'
            }
            this.loading = false
            this.$router.push('/home')
          }, (err) => {
            this.loading = false
          })
        } else {
          return false
        }
      })
    },
    gotoForgot() {
      this.$router.push('/forgot')
    },
    gotoSign() {
      this.$router.push('/register')
    }
  },
  created () {
    if (this.userInfo) {
      this.loginForm.email = this.userInfo.email
    }
  },
  mounted () {},
  destroyed () {}
}
</script>